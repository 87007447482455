import React, {useCallback, useEffect, useState} from "react";
import {
  Input,
  Typography,
  Button,
  Spin,
  Form,
  Switch,
  notification,
  Select, InputNumber,
} from "antd";
import { get, put } from "../../../api/axios";
import { Link, useNavigate } from "react-router-dom";
import { useParams } from "react-router";
import { ArrowLeftOutlined } from "@ant-design/icons";
import ReactQuill from "react-quill";

const { Title } = Typography;

const EditCampaign = (callback, deps) => {
  const navigate = useNavigate();
  let { id } = useParams();
  const [form] = Form.useForm();
  const [fromLoading, setFromLoading] = useState(false);
  const [inputsValue, setInputsValue] = useState({});
  const [loading, setLoading] = useState(false);
  const [optionCampaign, setOptionCampaign] = useState([]);
  const [optionAgent, setOptionAgent] = useState([]);
  const [optionProject, setOptionProject] = useState([]);
  const [optionStatus, setOptionStatus] = useState([]);
  const [optionUnit, setOptionUnit] = useState([]);
  const handleProjectId = useCallback((value) => {
    form.setFieldsValue({
      unit_id: '',
    });
    get(`get-agent-projects/${value}/units`).then(response => {
      let units = [];
      response.data.map((d) => {
        units.push({
          value: d.id,
          label: d.id + ' - ' + d.number +' - size: ' + d.area + ' - price: ' + d.price,
        });
        return d;
      });
      setOptionUnit(units);
    });
  }, [form]);

  useEffect(() => {
    get("get-campaign-packages").then((res) => {
      let packages = [];
      res.data.map((d) => {
        packages.push({
          value: d.id,
          label: d.cost,
        });
        return d;
      });
      setOptionCampaign(packages);
    });
    get("get-agent-users?is_registered=true").then((res) => {
      let agents = [];
      res.data.map((d) => {
        agents.push({
          value: d.id,
          label: d.id +' - '+ d.name + ' - '+ d.country_code + d.phone_number,
        });
        return d;
      });
      setOptionAgent(agents);
    });
    get("get-agent-projects").then((res) => {
      let projects = [];
      res.data.map((d) => {
        projects.push({
          value: d.id,
          label: d.name_en,
        });
        return d;
      });
      setOptionProject(projects);
    });
    get("/get-agent-dropdown-by-types?type=campaign_status").then((res) => {
      let options = [];
      res.data.map((d) => {
        options.push({
          value: d.id,
          label: d.name_en,
        });
        return d;
      });
      setOptionStatus(options);
    });
    get(`campaigns/${id}`).then(res => {
      if (res.data.project) {
        handleProjectId(res.data.project.id);
      }
      setInputsValue(res.data);
      setFromLoading(true);

      if (res.data.unit) {
        form.setFieldsValue({
          unit_id: res.data.unit.id,
        });
      }
    });
  }, [id]);

  const onFinish = values => {
    setLoading(true);
    const newValues = {
      ...values,
        show_agent_name: values.show_agent_name ? 1 : 0,
    };

    put(`campaigns/${id}`, newValues)
      .then(res => {
        notification.success({ message: "Modified successfully" });
        setLoading(false);
        navigate("/agent-campaigns");
      })
      .catch(err => {
        notification.error({
          message: "Error!",
          description: err.response.data.message,
        });
        setLoading(false);
      });
  };

  return (
    <div>
      <Title level={2} className="text-main mb-2">
        <Link className="text-dark me-3" to="/agent-campaigns">
          <ArrowLeftOutlined />
        </Link>
        Edit Campaign
      </Title>
      {fromLoading ? (
        <Form
            layout="vertical"
            className="wrapper-content"
          form={form}
          onFinish={onFinish}
          labelCol={{
            span: 9,
          }}
          wrapperCol={{
            span: 14,
          }}
          initialValues={{
            campaign_package_id: inputsValue.package.id,
            agent_id: inputsValue.agent.id,
            project_id: inputsValue.project.id,
            utm_source: inputsValue.utm_source,
            utm_medium: inputsValue.utm_medium,
            utm_campaign: inputsValue.utm_campaign,
            utm_content: inputsValue.utm_content,
            status_id: inputsValue.status?.id ?? 0,
            show_agent_name: inputsValue.show_agent_name,
            message_en: inputsValue.message_en,
            message_ar: inputsValue.message_ar,
            unit_id: inputsValue.unit.id,
            campaign_spent_percentage: inputsValue.campaign_spent_percentage,
          }}
        >
          <div className="row">
            {/* Item */}
            <div className="col-lg-6">
              <Form.Item
                label="Campaign Package"
                name="campaign_package_id"
                className="full-width-label"
                rules={[
                  {
                    required: true,
                    message: "This field is required!",
                  },
                ]}
              >
                <Select
                  allowClear
                  showSearch
                  optionFilterProp="children"
                  className="w-100"
                  filterOption={(input, option) =>
                    (option?.label ?? "")
                      .toLowerCase()
                      .includes(input.toLowerCase())
                  }
                  placeholder="Select"
                  options={optionCampaign}
                />
              </Form.Item>
            </div>
            {/* Item */}
            <div className="col-lg-6">
              <Form.Item
                label="Agent"
                name="agent_id"
                className="full-width-label"
                rules={[
                  {
                    required: true,
                    message: "This field is required!",
                  },
                ]}
              >
                <Select
                  allowClear
                  showSearch
                  optionFilterProp="children"
                  className="w-100"
                  filterOption={(input, option) =>
                    (option?.label ?? "")
                      .toLowerCase()
                      .includes(input.toLowerCase())
                  }
                  placeholder="Select"
                  options={optionAgent}
                />
              </Form.Item>
            </div>
            {/* Item */}
            <div className="col-lg-6">
              <Form.Item
                label="Project"
                name="project_id"
                className="full-width-label"
                rules={[
                  {
                    required: true,
                    message: "This field is required!",
                  },
                ]}
              >
                <Select
                  allowClear
                  showSearch
                  optionFilterProp="children"
                  className="w-100"
                  filterOption={(input, option) =>
                    (option?.label ?? "")
                      .toLowerCase()
                      .includes(input.toLowerCase())
                  }
                  placeholder="Select"
                  options={optionProject}
                  onChange={handleProjectId}
                />
              </Form.Item>
            </div>
            {/* Item */}
            <div className="col-lg-6">
              <Form.Item
                label="Unit"
                name="unit_id"
                className="full-width-label"
                rules={[
                  {
                    required: true,
                    message: "This field is required!",
                  },
                ]}
              >
                <Select
                  allowClear
                  showSearch
                  optionFilterProp="children"
                  className="w-100"
                  filterOption={(input, option) =>
                    (option?.label ?? "")
                      .toLowerCase()
                      .includes(input.toLowerCase())
                  }
                  placeholder="Select"
                  options={optionUnit}
                />
              </Form.Item>
            </div>
            {/* Item */}
            <div className="col-lg-6">
              <Form.Item
                label="Utm Source"
                className="full-width-label"
                name="utm_source"
              >
                <Input placeholder="Type..." />
              </Form.Item>
            </div>
            {/* Item */}
            <div className="col-lg-6">
              <Form.Item
                label="Utm Medium"
                className="full-width-label"
                name="utm_medium"
              >
                <Input placeholder="Type..." />
              </Form.Item>
            </div>
            {/* Item */}
            <div className="col-lg-6">
              <Form.Item
                label="Utm Campaign"
                className="full-width-label"
                name="utm_campaign"
              >
                <Input placeholder="Type..." />
              </Form.Item>
            </div>
            {/* Item */}
            <div className="col-lg-6">
              <Form.Item
                label="Utm Content"
                className="full-width-label"
                name="utm_content"
              >
                <Input placeholder="Type..." />
              </Form.Item>
            </div>
            {/* Item */}
            <div className="col-lg-6">
              <Form.Item
                  label="Message to show after lead submission EN"
                  className="full-width-label"
                  name="message_en"
              >
                <ReactQuill theme="snow" showCount maxLength={100} />
              </Form.Item>
            </div>
            {/* Item */}
            <div className="col-lg-6">
              <Form.Item
                  label="Message to show after lead submission AR"
                  className="full-width-label"
                  name="message_ar"
              >
                <ReactQuill theme="snow" showCount maxLength={100} />
              </Form.Item>
            </div>
            {/* Item */}
            <div className="col-lg-6">
              <Form.Item
                  label="Status"
                  className="full-width-label"
                  name="status_id"
                  rules={[
                    {
                      required: true,
                      message: "This field is required!",
                    },
                  ]}
              >
                <Select
                    allowClear
                    showSearch
                    optionFilterProp="children"
                    className="w-100"
                    filterOption={(input, option) =>
                        (option?.label ?? "")
                            .toLowerCase()
                            .includes(input.toLowerCase())
                    }
                    placeholder="Select"
                    options={optionStatus}
                />
              </Form.Item>
            </div>
            {/* Item */}
            <div className="col-lg-6">
              <Form.Item label="Show Agent name Above the form" className="full-width-label" name="show_agent_name">
                <Switch defaultChecked={inputsValue.show_agent_name} />
              </Form.Item>
            </div>

            {/* Item */}
            <div className="col-lg-6">
              <Form.Item
                  label="Campaign Spent Percentage"
                  className="full-width-label"
                  name="campaign_spent_percentage"
              >
                <InputNumber min={0} max={100} style={{ width: '100%' }}
                             formatter={(value) => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                             parser={(value) => value?.replace(/\$\s?|(,*)/g, '')}
                             placeholder="Type..." />
              </Form.Item>
            </div>
          </div>
          <div className="row">
            <div className="col-lg-4 offset-lg-4">
              <Form.Item
                wrapperCol={{
                  offset: 1,
                  span: 16,
                }}
              >
                <Button
                  loading={loading}
                  size="large"
                  block
                  type="primary"
                  htmlType="submit"
                >
                  {loading ? "Updating..." : "Update"}
                </Button>
              </Form.Item>
            </div>
          </div>
        </Form>
      ) : (
        <div className="text-center">
          <Spin size="large" />
        </div>
      )}
    </div>
  );
};

export default EditCampaign;
