import React, { useState, useEffect } from "react";
import {Upload, Modal, notification, Progress, Form, Button} from "antd";
import {PlusOutlined, UploadOutlined} from "@ant-design/icons";
import { postFromData } from "../../../api/axios";

const ImageUploadComponent = ({ onImageUpload, onImageDelete, image, setImage, storagePath }) => {
    const [previewOpen, setPreviewOpen] = useState(false);
    const [previewImage, setPreviewImage] = useState('');
    const [previewTitle, setPreviewTitle] = useState('');
    const [uploading, setUploading] = useState(false);
    const [progress, setProgress] = useState(0);

    // Update `fileList` to ensure each file has a proper URL and name
    useEffect(() => {
        if (image) {
            console.log(image)
            setPreviewImage(image.url || '');
            setPreviewTitle(image.name || 'Uploaded Image');
        }
    }, [image]);

    const handlePreview = (file) => {
        setPreviewImage(file.url || file.preview);
        setPreviewOpen(true);
        setPreviewTitle(file.name || file.url.substring(file.url.lastIndexOf('/') + 1));
    };

    const handleBeforeUpload = async (file) => {
        setUploading(true);
        let simulatedProgress = 0;
        const progressInterval = setInterval(() => {
            simulatedProgress += 5;
            setProgress(Math.min(simulatedProgress, 95)); // Cap progress at 95%
        }, 200);

        try {
            const response = await postFromData('/upload-image', { 'image': file, 'store_path': storagePath });
            clearInterval(progressInterval);
            setProgress(100); // Set progress to 100% on success

            const imagePath = response.data;
            file.url = process.env.REACT_APP_BASE_IMAGE_URL + imagePath;
            file.path = imagePath;

            setImage((prev) => [...prev, {
                ...file,
                uid: file.uid || `${file.url.substring(file.url.lastIndexOf('/') + 1)}-${new Date().getTime()}`
            }]);
            onImageUpload(imagePath);

            notification.success({
                message: "Upload Successful",
                description: `${file.name} uploaded successfully!`,
            });
        } catch (error) {
            notification.error({
                message: "Error!",
                description: "Image upload failed.",
            });
        } finally {
            setUploading(false);
            setProgress(0);
        }
        return false;
    };
    const handleRemove = (file) => {
        console.log(file);
        const filePath =  file.url || process.env.REACT_APP_BASE_IMAGE_URL + file.path;
        onImageDelete(filePath); // This will now call the correct function passed from the parent
    };
    return (
        <>
            <Upload
                onPreview={handlePreview}
                beforeUpload={handleBeforeUpload}
                onRemove={handleRemove}
                accept=".png,.jpg,.jpeg,.svg"
                listType="picture"
                fileList={image}
                maxCount={1}
            >
                <Button icon={<UploadOutlined />}> Click to upload an image </Button>
            </Upload>
            <Modal open={previewOpen} title={previewTitle} footer={null} onCancel={() => setPreviewOpen(false)}>
                <img alt="preview" style={{ width: '100%' }} src={previewImage} />
            </Modal>
            {uploading && <Progress percent={progress} />}
        </>
    );
};

export default ImageUploadComponent;
