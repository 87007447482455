import React, {useCallback, useEffect, useState} from "react";
import { Button, Typography, Form, Select, Input, Tag, notification } from "antd";
import { get } from "../../../api/axios";
import { Link } from "react-router-dom";
import { DeleteOutlined, EditOutlined } from "@ant-design/icons";
import { checkPermission } from "../../control-sections/auth/ProtectedRoutes";
import { onChangeTableHelper } from "../../control-sections/helper/TableHelpers";
import TableComponent from "../../control-sections/helper/TableComponent";
import FormItemLabel from "antd/es/form/FormItemLabel";

const Campaigns = () => {
    const { Title } = Typography;
    const [form] = Form.useForm();
    const queryParams = new URLSearchParams(window.location.search);
    const filterByUserId = queryParams.get('user_id') || null;

    const [data, setData] = useState([]);
    const [loading, setLoading] = useState(true);
    const [loadingSearch, setLoadingSearch] = useState(false);
    const [campaignStatus, setCampaignStatus] = useState(null); // New state for campaign status filter
    const [total, setTotal] = useState(1);

    const columns = [
        {
            title: " Id",
            dataIndex: "id",
            sorter: (a, b) => a.id - b.id,
        },
        {
            title: " Agent",
            render: ({agent}) => (
                agent?.name ?? 'Agent Name'
            ),
        },
        {
            title: " Package",
            render: (data) => (
                data?.package?.cost+ ' SRA' ?? 0 + ' SRA'
            ),
        },
        {
            title: " Project",
            render: (data) => (
                <>
                    {data.project.name ?? 'Project Name'}
                </>
            ),
        },
        {
            title: " Unit",
            render: (data) => (
                <>
                    {data.unit.number ?? 'Unit Number'}
                </>
            ),
        },
        {
            title: "Leads",
            dataIndex: "number_of_leads",
            sorter: (a, b) => a.number_of_leads - b.number_of_leads,
        },
        {
            title: "Cost / Lead",
            dataIndex: "cost_per_lead"
        },
        {
            title: "Status",
            render: ({ status }) => (
                <>
                    <Tag color="success">
                        {status?.name_en ?? 'Status'}
                    </Tag>
                </>
            ),
        },
    ];

    if (
        checkPermission("update-campaign") ||
        checkPermission("delete-campaign")
    ) {
        columns.push({
            title: "Actions",
            dataIndex: "id",
            render: (id, data) => (
                <div>
                    {
                        data.status?.name_en == 'active' ?
                            <>
                                <Button className="btn btn-outline-info btn-edit btn-sm p-0 me-3">
                                    <Link
                                        target={"_blank"}
                                        className="color-inherit text-decoration-none py-1 px-2 d-block"
                                        to={`/campaigns/en/${id}/?${data.utm_source !== null ? 'utm_source='+data.utm_source+'&' : ''}${data.utm_medium !== null ? 'utm_medium='+data.utm_medium+'&': ''}${data.utm_campaign !== null ? 'utm_campaign='+data.utm_campaign+'&': ''}${data.utm_content !== null ? 'utm_content='+data.utm_content: ''}`}
                                    >
                                        Campaign Form
                                    </Link>
                                </Button>
                                <Button className="btn btn-outline-info btn-edit btn-sm p-0 me-3">
                                    <Link
                                        target={"_blank"}
                                        className="color-inherit text-decoration-none py-1 px-2 d-block"
                                        to={`/campaigns/ar/${id}/?${data.utm_source !== null ? 'utm_source='+data.utm_source+'&' : ''}${data.utm_medium !== null ? 'utm_medium='+data.utm_medium+'&': ''}${data.utm_campaign !== null ? 'utm_campaign='+data.utm_campaign+'&': ''}${data.utm_content !== null ? 'utm_content='+data.utm_content: ''}`}
                                    >
                                        رابط الحملة
                                    </Link>
                                </Button>
                            </>
                            : <></>
                    }
                    {checkPermission("update-campaign") ? (
                        <Button className="btn btn-outline-warning btn-edit btn-sm p-0 me-3">
                            <Link
                                className="color-inherit text-decoration-none py-1 px-2 d-block"
                                to={`/agent-campaigns/edit/${id}`}
                            >
                                <EditOutlined />
                            </Link>
                        </Button>
                    ) : (
                        <></>
                    )}
                    {/*{checkPermission("delete-campaign") ? (*/}
                    {/*    <Popconfirm*/}
                    {/*        title="Delete item"*/}
                    {/*        description="Are you sure you want to delete this item?"*/}
                    {/*        onConfirm={() => confirm(id)}*/}
                    {/*        onCancel={cancel}*/}
                    {/*        okText="OK"*/}
                    {/*        cancelText="Cancel"*/}
                    {/*    >*/}
                    {/*        <Button danger className="flex-center d-inline-flex">*/}
                    {/*            <DeleteOutlined />*/}
                    {/*        </Button>*/}
                    {/*    </Popconfirm>*/}
                    {/*) : (*/}
                    {/*    <></>*/}
                    {/*)}*/}

                </div>
            ),
        });
    }

    columns.push(
        {
            title: "utm_source",
            dataIndex: "utm_source",
            sorter: (a, b) => a.utm_source - b.utm_source,
        },
        {
            title: "utm_medium",
            dataIndex: "utm_medium",
            sorter: (a, b) => a.utm_medium - b.utm_medium,
        },
        {
            title: "utm_campaign",
            dataIndex: "utm_campaign",
            sorter: (a, b) => a.utm_campaign - b.utm_campaign,
        },
        {
            title: "utm_content",
            dataIndex: "utm_content",
            sorter: (a, b) => a.utm_content - b.utm_content,
        }
    );
    const [optionStatus, setOptionStatus] = useState([]);

    useEffect(() => {
        let options = [];
        get("/get-agent-dropdown-by-types?type=campaign_status").then((res) => {
            res.data.map((d) => {
                options.push({
                    value: d.id,
                    label: d.name_en,
                });

                return d;
            });
            setOptionStatus(options);
            setCampaignStatus(options.find((item) => item.label == 'active')?.value);
        });
    }, []);
    const fetchData = useCallback(() => {
        let url = 'campaigns?';
        if (
            filterData.filter_key !== "" && filterData.filter_key !== undefined &&
            filterData.filter_value !== "" && filterData.filter_value !== undefined
        ) {
            url += `filter_key=${filterData.filter_key}&filter_value=${filterData.filter_value}&`;
        }

        
        get(`limit=50&sort_by=id&order=asc&page=1&campaign_status=${campaignStatus ?? ""}`)
            .then((res) => {
                setData(res.data.data);
                setTotal(res.data.meta.total);
                setLoading(false);
            });
    }, [campaignStatus]);

    const [filterData, setFilterData] = useState({
        filter_key: "",
        filter_value: "",
    });

    const [sorter, setSorter] = useState({
        sort_by: "",
        order: "",
    });

    useEffect(() => {
        setLoading(true);
        fetchData();
    }, [campaignStatus, fetchData]);

    useEffect(() => {
        console.log(filterByUserId);
        if (filterByUserId !== null) {
            setFilterData({ filter_key: 'agent_id', filter_value: filterByUserId });
        }
        fetchData();
    }, [filterByUserId]);
    const handleFilterKey = (e) => {
        setFilterData({ ...filterData, filter_key: e });
    };

    const handleFilterVal = (e) => {
        setFilterData({ ...filterData, [e.target.id]: e.target.value });
    };

    const onFilter = (values) => {
        setLoadingSearch(true);
        get(`campaigns?
                    filter_key=${filterData.filter_key}&
                    filter_value=${filterData.filter_value}
                    ${sorter.sort_by ? `&sort_by=${sorter.sort_by}` : ""}
                    ${sorter.order ? `&order=${sorter.order}` : ""}
                    ${campaignStatus ? `&campaign_status=${campaignStatus}` : ""}`)
            .then((res) => {
                setData(res.data.data);
                setTotal(res.data.meta.total);
                setLoadingSearch(false);
            })
            .catch((err) => {
                notification.error({
                    message: "Error!",
                    description: err.response.data.message,
                });
                setLoadingSearch(false);
            });
    };

    const clearSearch = () => {
        form.resetFields();
        setFilterData({ filter_key: "", filter_value: "" });
        setCampaignStatus(null); // Reset campaign status
        fetchData();
    };

    const onChangeTable = (pagination, filters, sorter) => {
        setLoading(true);
        setSorter({
            ...sorter,
            sort_by: sorter.field,
            order: sorter.order == "descend" ? "desc" : "asc",
        });

        onChangeTableHelper('campaigns', pagination, filterData, sorter)
            .then((res) => {
                setData(res.data.data);
                setTotal(res.data.meta.total);
                setLoading(false);
            })
            .catch((err) => {
                notification.error({
                    message: "Error!",
                    description: err.response.data.message,
                });
                setLoading(false);
            });
    };

    return (
        <div>
            <div className="row">
                <div className="col-lg-12">
                    <div className="row">
                        <div className="col-lg-6">
                            <Title level={2} className="text-main mb-2">
                                Campaign
                            </Title>
                        </div>
                        {checkPermission("create-campaign") ? (
                            <div className="col-lg-3 offset-lg-3 text-end">
                                <Button type="primary" className="p-0" size="large">
                                    <Link
                                        className="text-decoration-none h-100 flex-center py-2 px-4"
                                        to="/agent-campaigns/add"
                                    >
                                        Add Campaign
                                    </Link>
                                </Button>
                            </div>
                        ) : (
                            <></>
                        )}
                    </div>
                </div>
            </div>
            <div className="grid-margin stretch-card wrapper-content">
                <div className="row mb-3">
                    <div className="col-12 col-lg-4">
                        <FormItemLabel prefixCls="ant-form-item" label="Filter by Campaign Status"/>
                        <Select
                            className="w-100"
                            allowClear
                            showSearch
                            placeholder="Filter by Campaign Status"
                            options={optionStatus}
                            defaultValue={campaignStatus}
                            onChange={(value) => setCampaignStatus(value)}
                        />
                    </div>
                </div>
                <Form onFinish={onFilter} layout="vertical" form={form}>
                    <div className="row">
                        <div className="col-12 col-lg-4">
                            <Form.Item
                                label="Search By"
                                name="filter_key"
                                rules={[
                                    {
                                        required: true,
                                        message: "This field is required!",
                                    },
                                ]}
                            >
                                <Select
                                    className="w-100"
                                    showSearch
                                    placeholder="select test "
                                    optionFilterProp="children"
                                    filterOption={(input, option) =>
                                        (option?.label ?? "")
                                            .toLowerCase()
                                            .includes(input.toLowerCase())
                                    }
                                    onChange={handleFilterKey}
                                    options={[
                                        {
                                            value: "agent_name",
                                            label: "Agent Name",
                                        },
                                        {
                                            value: "agent_phone_number",
                                            label: "Agent Phone Number",
                                        },
                                        {
                                            value: "agent_id",
                                            label: "Agent ID",
                                        },
                                        {
                                            value: "project_name",
                                            label: "Project Name",
                                        },
                                        {
                                            value: "project_id",
                                            label: "Project ID",
                                        },
                                        {
                                            value: "unit_number",
                                            label: "Unit Name",
                                        },
                                        {
                                            value: "unit_id",
                                            label: "Unit ID",
                                        },
                                        {
                                            value: "utm_source",
                                            label: "Utm Source",
                                        },
                                        {
                                            value: "utm_medium",
                                            label: "Utm Medium",
                                        },
                                        {
                                            value: "utm_campaign",
                                            label: "Utm Campaign",
                                        },
                                        {
                                            value: "utm_content",
                                            label: "Utm Content",
                                        },
                                        {
                                            value: "campaign_status",
                                            label: "Status",
                                        },
                                        {
                                            value: "name_ar",
                                            label: "Name Ar",
                                        },
                                        {
                                            value: "name_en",
                                            label: "Name En",
                                        },
                                        {
                                            value: "id",
                                            label: "Id",
                                        },
                                    ]}
                                />
                            </Form.Item>
                        </div>
                        <div className="col-12 col-lg-6">
                            <Form.Item
                                label="Search"
                                name="filter_value"
                                onChange={handleFilterVal}
                                rules={[
                                    {
                                        required: true,
                                        message: "This field is required!",
                                    },
                                ]}
                            >
                                <Input placeholder="Type to search..."/>
                            </Form.Item>
                        </div>
                        <div className="col-12 col-lg-2 d-flex align-items-end">
                            <Form.Item className="w-100">
                                <div className="row">
                                    <div className="col-8">
                                        <Button
                                            block
                                            loading={loadingSearch}
                                            type="primary"
                                            htmlType="submit"
                                        >
                                            {loadingSearch ? "Searching" : "Search"}
                                        </Button>
                                    </div>
                                    <div className="col-4">
                                        <Button
                                            onClick={clearSearch}
                                            className="flex-center"
                                            danger
                                            type="primary"
                                        >
                                            <DeleteOutlined/>
                                        </Button>
                                    </div>
                                </div>
                            </Form.Item>
                        </div>
                    </div>
                </Form>
                <TableComponent onChangeTable={onChangeTable} loading={loading} columns={columns} data={data}
                                total={total}/>
            </div>
        </div>
    );
};

export default Campaigns;
