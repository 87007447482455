import React, { useEffect, useState } from "react";
import {
  Input,
  Typography,
  Button,
  Spin,
  Form,
  notification,
  Select,
} from "antd";
import { get, put } from "../../../api/axios";
import { Link, useNavigate } from "react-router-dom";
import { useParams } from "react-router";
import { ArrowLeftOutlined } from "@ant-design/icons";

const { Title } = Typography;

const EditAgentCustomer = () => {
  const navigate = useNavigate();
  let { id } = useParams();

  const [fromLoading, setFromLoading] = useState(false);

  const [inputsValue, setInputsValue] = useState({});

  useEffect(() => {
    get(`agent-customers/${id}`).then(res => {
      setInputsValue(res.data);
      setFromLoading(true);
    });
  }, [id]);

  const [loading, setLoading] = useState(false);



  const [dataType, setDataType] = useState([]);
  const [optionType, setOptionType] = useState([]);

  useEffect(() => {
    setOptionType(
      dataType.map((d) => ({
        value: d.id,
        label: d.id +' - '+ d.name + ' - '+ d.country_code + d.phone_number,
      }))
    );
  }, [dataType]);

  const [dataCustomer, setDataCustomer] = useState([]);
  const [optionCustomer, setOptionCustomer] = useState([]);
  const [optionCustomerStatus, setOptionCustomerStatus] = useState([]);

  useEffect(() => {
    const filterData = dataCustomer.filter(item => item.type == 'customer_source')
    const filterData2 = dataCustomer.filter(item => item.type == 'customer_status')
    setOptionCustomer(
      filterData.map((d) => ({
        value: d.id,
        label: d.name_en,
      }))
    );
    setOptionCustomerStatus(
      filterData2.map((d) => ({
        value: d.id,
        label: d.name_en,
      }))
    );
  }, [dataCustomer]);

  useEffect(() => {
    get("get-agent-users?is_registered=true").then((res) => {
      setDataType(res.data);
    });
    get("/agent-dropdowns").then((res) => {
      setDataCustomer(res.data.data);
    });
  }, []);

  const onFinish = values => {
    setLoading(true);
    const newValues = {
      ...values,
    }

    put(`agent-customers/${id}`, newValues)
      .then(res => {
        notification.success({ message: "Modified successfully" });
        setLoading(false);
        navigate("/agent-customers");
      })
      .catch(err => {
        notification.error({
          message: "Error!",
          description: err.response.data.message,
        });
        setLoading(false);
      });
  };

  return (
    <div>
      <Title level={2} className="text-main mb-2">
        <Link className="text-dark me-3" to="/agent-customers">
          <ArrowLeftOutlined />
        </Link>
        Edit agent customer
      </Title>
      {fromLoading ? (
        <Form
          className="wrapper-content"
          onFinish={onFinish}
          labelCol={{
            span: 9,
          }}
          wrapperCol={{
            span: 14,
          }}
          initialValues={{
            name: inputsValue.name,
            phone_number: inputsValue.phone_number,
            customer_source_id: inputsValue.customer_source_id,
            customer_status_id: inputsValue.customer_status_id,
            agent_id: inputsValue.agent_id,
            notes: inputsValue.notes,
          }}
        >
          <div className="row">
            {/* Item */}
            <div className="col-lg-6">
              <Form.Item
                label="Agent"
                name="agent_id"
                rules={[
                  {
                    required: true,
                    message: "This field is required!",
                  },
                ]}
              >
                <Select
                  allowClear
                  showSearch
                  optionFilterProp="children"
                  className="w-100"
                  filterOption={(input, option) =>
                    (option?.label ?? "")
                      .toLowerCase()
                      .includes(input.toLowerCase())
                  }
                  placeholder="Select"
                  options={optionType}
                />
              </Form.Item>
            </div>
            {/* Item */}
            <div className="col-lg-6">
              <Form.Item
                label="Agent"
                name="customer_source_id"
                rules={[
                  {
                    required: true,
                    message: "This field is required!",
                  },
                ]}
              >
                <Select
                  allowClear
                  showSearch
                  optionFilterProp="children"
                  className="w-100"
                  filterOption={(input, option) =>
                    (option?.label ?? "")
                      .toLowerCase()
                      .includes(input.toLowerCase())
                  }
                  placeholder="Select"
                  options={optionCustomer}
                />
              </Form.Item>
            </div>
            {/* Item */}
            <div className="col-lg-6">
              <Form.Item
                label="Status"
                name="customer_status_id"
                rules={[
                  {
                    required: true,
                    message: "This field is required!",
                  },
                ]}
              >
                <Select
                  allowClear
                  showSearch
                  optionFilterProp="children"
                  className="w-100"
                  filterOption={(input, option) =>
                    (option?.label ?? "")
                      .toLowerCase()
                      .includes(input.toLowerCase())
                  }
                  placeholder="Select"
                  options={optionCustomerStatus}
                />
              </Form.Item>
            </div>
            {/* Item */}
            <div className="col-lg-6">
              <Form.Item
                label="Phone"
                name="phone_number"
                rules={[
                  {
                    required: true,
                    message: "This field is required!",
                  },
                ]}
              >
                <Input type="number" />
              </Form.Item>
            </div>

            {/* Item */}
            <div className="col-lg-6">
              <Form.Item
                label="Name"
                name="name"
                rules={[
                  {
                    required: true,
                    message: "This field is required!",
                  },
                ]}
              >
                <Input placeholder="Type..." />
              </Form.Item>
            </div>
            {/* Item */}
            <div className="col-lg-6">
              <Form.Item
                label="Notes"
                name="notes"
              >
                <Input placeholder="Type..." />
              </Form.Item>
            </div>
          </div>
          <div className="row">
            <div className="col-lg-4 offset-lg-4">
              <Form.Item
                wrapperCol={{
                  offset: 1,
                  span: 16,
                }}
              >
                <Button
                  loading={loading}
                  size="large"
                  block
                  type="primary"
                  htmlType="submit"
                >
                  {loading ? "Updating..." : "Update"}
                </Button>
              </Form.Item>
            </div>
          </div>
        </Form>
      ) : (
        <div className="text-center">
          <Spin size="large" />
        </div>
      )}
    </div>
  );
};

export default EditAgentCustomer;
